import React from 'react'
import styled from "styled-components";
import SmallContact from './SmallContact'
import { device } from '../Device/Device'



const Globalwrapper = styled.section `
position: relative;
display: block;
top: -187px;
padding-top: 60px;
padding-bottom: 60px;
overflow-x: hidden;

.small-contact-wrapper {
  flex-basis: 100%;
  
}

@media ${device.laptop} {
display: flex;
.small-contact-wrapper {
  flex-basis: 60%;
  padding-right: 15px;
  
}
}
`

const Wrapper = styled.article`
max-width: 100% ;
margin: 0 auto;


h1 {
    position: relative;
    display: block;
    width: max-content;
    font-weight: normal;
    padding-top: 60px;
}
p {
  padding-bottom: 60px;
}

.btn {
    width: 100%;
    background-color: #47bcca;
    border-color: #47bcca;
    color: #fff;
}
.btn::first-letter{
    text-transform: capitalize;
}
::placeholder {
    color:#293B5C ;
}
@media ${device.laptop} {
  flex-basis: 40%;
  max-width: 420px ;
}
`
const Label = styled.label`
display: block;
border-radius: 10px;
  margin-bottom: 0.75rem;
  text-transform: capitalize;
`
const Form = styled.form `
    width: 100%;
    background: transparent;
   
    `
const Input = styled.input  `

  width: 100%;
  height: 40px;
  padding: 0.375rem 0.75rem;
  border-radius: 10px;
  background: #fff;
  border: 2px solid #B3C5E5;
  
  `
  const Textarea = styled.textarea`
  width: 100%;
  height: 206px;
  padding: 0.375rem 0.75rem;
  border-radius: 10px;
  background: #fff;
  border: 2px solid #B3C5E5;
  `
const Formrow = styled.div `
    margin-bottom: 1rem;
    `
  


const Contact = () => {


    return (
        <Globalwrapper className="globalWrapper" >
          <div className="small-contact-wrapper">
          <SmallContact/>
          </div> 
            
   <Wrapper id="contact">
        

    
           
        <Form className="form contact-form">
          <Formrow className="form-row">
            <Label htmlFor="name">imię</Label>
            <Input type="text" name="name" id="name" />
          </Formrow>
          <Formrow className="form-row">
            <Label htmlFor="surname">nazwisko</Label>
            <Input type="text" name="surname" id="surname" />
          </Formrow>
          <Formrow className="form-row">
            <Label htmlFor="email">e-mail</Label>
            <Input type="text" name="email" id="email" />
          </Formrow>
          <Formrow className="form-row">
            <Label htmlFor="message">wiadomość</Label>
            <Textarea name="message" id="message"></Textarea>
          </Formrow>
          <button type="submit" className="btn block">
            wyślij wiadomość
          </button>
        </Form>
      </Wrapper>
      </Globalwrapper>
      
    )
}

export default Contact
