import React from 'react'
import {useStaticQuery, graphql} from "gatsby"
import styled from 'styled-components'
import Counter from './Counter';
import { device } from '../Device/Device';




const Globalwrapper = styled.section`
position: relative;
top: -187px;
width: 100%;
background-color: #47bcca;;
margin-left: auto;
margin-right: auto;
color: #fff;
padding: 5rem 0;
@media (min-width: 992px) {
      width: 100%
}
`



const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  
.selling-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media ${device.laptop} {
    width: unset;
  }
}
.selling-desc {
  padding-top: 2rem;
}
  p{
    font-size: 18px;
    opacity: .75;
    text-align: center;
    &::first-letter {
      text-transform: uppercase;
    }
  }

  span {
    font-size: 48px;
    padding-bottom: 2rem;
  }
  
`

const Sellingpoint = () => {
  const data = useStaticQuery(graphql`
  {
    allMarkdownRemark(filter: {frontmatter: {categories: {eq: "Selling-Point"}}}) {
      edges {
        node {
          html
          frontmatter {
            number
          }
        }
      }
    }
  }
`)

const node = data.allMarkdownRemark.edges

  return (
<Globalwrapper>
          
    <Wrapper className="globalWrapper">
    {node.map(({node}) => {
          
     const end = node.frontmatter.number  
  return (
    
    <div className="selling-wrapper">
    <Counter start={0} end={end}   />
    <div className="selling-desc" dangerouslySetInnerHTML={{ __html:` ${node.html}`}}/>
  </div>
              )
        })} 


    
      
    </Wrapper>
    </Globalwrapper>
  )
}




export default Sellingpoint
