import React, {useRef} from 'react'
import { device } from '../Device/Device'
import {useStaticQuery, graphql} from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import 'swiper/swiper-bundle.css'
import { AiFillRightCircle } from '@react-icons/all-files/ai/AiFillRightCircle';
import { AiFillLeftCircle } from '@react-icons/all-files/ai/AiFillLeftCircle';
SwiperCore.use([Navigation]);




const Globalwrapper = styled.section `
position: relative;
background-color: #fff;
width: 100%;
margin-bottom: 172px ;
background-color: transparent;
overflow-x: hidden;
`
const Wrapper = styled.div `

.swiper-container {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  
}
.swiper-wrapper {
  width: 100%;
  z-index: -1;
}
.navigation {
  width: 100%;
  justify-items: center;
  z-index: 2;
  flex-shrink: 1;
  background-color: #fff;
  padding: 60px 0;
  
}
.cursor {
  display: flex;
  justify-content: center;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-pointer:nth-of-type(1) {
  margin-right: 60px;
} 

.item {
  display: flex;
  
  width: 100%;
  height: 100%;
  border-radius: 12px;
  box-shadow: 0px 4px 4px 0 rgba(0, 0, 0, 0.25);
  padding: 60px 42px 60px 9px;
  margin-bottom: 30px;
}
.description {
  width: 100%;
  margin-left: 24px;
}
.about-img {
  height: 100%;
  border-radius: 180px;
  display: none;
  @media ${device.laptop} {
    display: block;
  }
}
h1 {
  text-align: center;
}
.navigation svg {
  color: #47bcca;
  height: 30px;
  width: 30px;
  transition: 300ms;
  &:hover {
   transform: scale(2) ;
  }
}
@media ${device.laptop} {
  .swiper-container {
    flex-direction: row-reverse;
    
  }
  .navigation {
    width: 100%;
  }
  .swiper-wrapper {
    width: 50%;
  }
  .item {
    width: 47%;
    margin-left: 10px;
  }
}

`








const Reputation = () => {
  const data = useStaticQuery(graphql`
  {
    allMarkdownRemark(sort: {fields: frontmatter___order}
    filter: {frontmatter: {categories: {eq: "reputation"}}}) {
      edges {
        node {
          html
          frontmatter {
            title
            image_reputation_alt
            ImageReputation {
              childImageSharp {
                gatsbyImageData(width: 100, layout: CONSTRAINED, placeholder: TRACED_SVG)
              }
            }
          }
        }
      }
    }
  }
`)
    
  
 
  const node = data.allMarkdownRemark.edges
  
  
  const prevRef = useRef(null);
  const nextRef = useRef(null);
 
 


  return (
		<Globalwrapper id="custom" className="globalWrapper">
			<Wrapper>
				<Swiper
					className="container"
					spaceBetween={16}
					slidesPerView={1}
					loop
					navigation={{
						prevEl: prevRef.current ? prevRef.current : undefined,
						nextEl: nextRef.current ? nextRef.current : undefined,
					}}
					onInit={(swiper) => {
						swiper.params.navigation.prevEl = prevRef.current;
						swiper.params.navigation.nextEl = nextRef.current;
					}}
					breakpoints={{
						320: {
							slidesPerView: 1,
						},
						991: {
							slidesPerView: 1,
						},
					}}
				>
					{node.map(({ node }, index) => {
						const image = getImage(node.frontmatter.ImageReputation);

						return (
							<SwiperSlide className="reputation-desc">
								<article key={index} className="item">
									<GatsbyImage
										image={image}
										alt={node.image_reputation_alt}
										className="about-img"
									/>
									<div className="description">
										<h2>{node.frontmatter.title}</h2>
										<div
											dangerouslySetInnerHTML={{ __html: ` ${node.html}` }}
										/>
									</div>
								</article>
							</SwiperSlide>
						);
					})}
					<div className="navigation">
						<h1>Opinie o nas</h1>
						<div className="cursor">
							<div ref={prevRef} className="cursor-pointer">
								<span className="prev">
									<AiFillLeftCircle />
								</span>
							</div>
							<div ref={nextRef} className="cursor-pointer">
								<span className="next">
									<AiFillRightCircle />
								</span>
							</div>
						</div>
					</div>
				</Swiper>
			</Wrapper>
		</Globalwrapper>
	);
    }


export default Reputation
