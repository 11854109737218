import React from 'react'
import {useStaticQuery, graphql} from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from 'styled-components'
import Button from '../Buttons/Button'
import { device } from '../Device/Device'
import ScrollAnimation from 'react-animate-on-scroll';




const Globalwrapper = styled.section`
position: relative;
top: -116px;
left: 0;
z-index: 3;
width: 100%;
background-color: #47bcca;
border-radius: 12px;
margin-left: auto;
margin-right: auto;
color: #fff;
padding: 60px 0;
overflow-x: hidden;
@media ${device.tablet} {
      width: 90%;
      top: -136px;
}

`
const Title = styled.h1`
display: block;
width: fit-content;
position: relative;
margin: 0 auto;
font-weight: normal;
   
`

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 1rem;
  
  
  

  .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;
      flex-basis: 100%;
      padding-top: 30px;
      text-align: left;
 
      @media (min-width: 768px){
          flex-basis: 40%;
          padding-top: 60px;
         
  
        p{
              flex-grow: 1;
              text-align: justify;
              height: 100px;
              text-align: justify;
              hyphens: auto;
              
              
          }
          h2{
              flex-grow: 1;
              text-align: center;
              height: 50px;
              margin-top: 20px;
          }

      }
      @media (min-width: 992px) {
          flex-basis: 25%;
          &:first-of-type a {
        display: none;
      }
      &:last-of-type a {
display: none;
      }
         
      }
    
  }
  .gallery-img {
      align-self: center;
    border-radius: 1rem;
  }
  
`

const Services = () => {
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(filter: {frontmatter: {categories: {eq: "services"}}}) {
        edges {
          node {
            html
            frontmatter {
              title
              desc
              image1alt
              Image01 {
                childImageSharp {
                  gatsbyImageData(width: 90, layout: CONSTRAINED, placeholder: TRACED_SVG)
                }
              }
            }
          }
        }
      }
    }
  `)

  
 
  
 
  // const image = getImage(data.Image01)
  
  

  const node = data.allMarkdownRemark.edges
 
return (
	<Globalwrapper id="services">
		<Title>Jak pomagamy</Title>
		<Wrapper className="globalWrapper">
			{node.map(({ node }, index) => {
				const image = getImage(node.frontmatter.Image01);

				return (
					<article key={index} className="item">
						<GatsbyImage
							image={image}
							alt={node.name}
							className="gallery-img"
						/>
						<ScrollAnimation animateIn="fadeInDown">
							<h2>{node.frontmatter.title}</h2>
						</ScrollAnimation>

						<div dangerouslySetInnerHTML={{ __html: ` ${node.html}` }} />

						<Button link="/#contact" name="Skontaktuj się z nami" />
					</article>
				);
			})}
		</Wrapper>
	</Globalwrapper>
);
}




export default Services
