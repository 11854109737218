import React from "react";

import ScrollAnimation from "react-animate-on-scroll";

import { Link } from "gatsby";

function Button({ link, name }) {
  return (
    <ScrollAnimation animateIn="flipInX">
      <Link className="btn" to={link}>
        {name}
      </Link>
    </ScrollAnimation>
  );
}

export default Button;
