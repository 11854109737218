import React from 'react'
import styled from 'styled-components'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import {useStaticQuery, graphql} from "gatsby"
import Button from "../Buttons/Button";
import { device } from '../Device/Device'
import ScrollAnimation from 'react-animate-on-scroll';
import { useWindowSize } from "../utils/use-windowsize";

const Globalwrapper = styled.section`
	position: relative;
	top: -187px;
	/* padding-top: calc(106px - 318px); */
	z-index: -1;
	overflow-x: hidden;
`;
const Wrappertop = styled.div`
	position: relative;
	display: flex;
	flex-wrap: wrap;
	height: 100%;
	padding-bottom: 50px;
	background-image: linear-gradient(
		0deg,
		rgba(180, 176, 176, 0.3) 4.55%,
		rgba(180, 176, 176, 0) 99.63%
	);
	z-index: 1;
	&:before {
		content: "";
		display: block;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 0;
		height: 0;
		border-top: 750px solid transparent;
		border-right: 100vw solid #fff;
		z-index: -1;
	}
	.grid {
		width: 100%;
	}
	.item {
		display: flex;
		flex-direction: column;
		width: 100%;
		margin-bottom: 30px;
	}
	.description {
		display: flex;
		flex-direction: column;
	}
	.description h2 {
		margin-top: 30px;
	}
	.description p {
		margin: 30px 0 30px 0;
	}
	.btn {
		background-color: #47bcca;
		color: #fff;
	}

	.gallery-img {
		height: 100%;
		max-height: 314px;
		border-radius: 20px;
		z-index: 1;
		height: 314px;
	}

	@media ${device.laptop} {
		&:after {
			border-top: 142px solid #b4b0b0;
		}
		.item.row-reverse {
			flex-direction: row-reverse;
		}
		.item.row {
			flex-direction: row;
		}

		.item {
			justify-content: space-between;
			align-items: center;
			margin-bottom: 120px;
		}
		.description {
			width: 40%;
			padding-right: 10px;
			padding-left: 10px;
		}
		.btn {
			width: 50%;
		}
	}
`;

const Title = styled.h1`
	padding: 195px 0px 0 0px;
	position: relative;
	font-weight: normal;
	white-space: pre-wrap;
`;

const Projects = () => {
	const data = useStaticQuery(graphql`
		{
			allMarkdownRemark(
				sort: { fields: frontmatter___order }
				filter: { frontmatter: { categories: { eq: "projects" } } }
			) {
				edges {
					node {
						frontmatter {
							title
							image_projects_alt
							imageprojects {
								childImageSharp {
									gatsbyImageData(
										width: 700
										layout: CONSTRAINED
										placeholder: NONE
									)
								}
							}
							imageprojects_mobile {
								childImageSharp {
									gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
								}
							}
						}
						html
					}
				}
			}
		}
	`);
	const node = data.allMarkdownRemark.edges;
	const { width } = useWindowSize();
	const breakpoint = 1024;
	return (
		<Globalwrapper>
			<Wrappertop id="project" className="globalWrapper">
				<Title>Co oferujemy</Title>
				{node.map(({ node }, index) => {
					const image = getImage(node.frontmatter.imageprojects);
					const imageMobile = getImage(node.frontmatter.imageprojects_mobile);

					return (
						<div>
							<div>
								{width < breakpoint && (
									<article key={index} className="item">
										<GatsbyImage
											image={imageMobile}
											alt={node.image_projects_alt}
											className="gallery-img"
										/>
										<div className="description">
											<ScrollAnimation delay={300} animateIn="fadeIn">
												<h2>{node.frontmatter.title}</h2>
											</ScrollAnimation>
											<div
												dangerouslySetInnerHTML={{ __html: ` ${node.html}` }}
											/>
											<Button link="/#contact" name="Skontaktuj się z nami" />
										</div>
									</article>
								)}
							</div>
							<div>
								{width >= breakpoint && (
									<article
										key={index}
										className={`item ${
											index % 2 === 0 ? "row" : "row-reverse"
										}`}
									>
										<GatsbyImage
											image={image}
											alt={node.image_projects_alt}
											className="gallery-img"
										/>
										<div className="description">
											<ScrollAnimation delay={300} animateIn="fadeIn">
												<h2>{node.frontmatter.title}</h2>
											</ScrollAnimation>
											<div
												dangerouslySetInnerHTML={{ __html: ` ${node.html}` }}
											/>
											<Button link="/#contact" name="Skontaktuj się z nami" />
										</div>
									</article>
								)}
							</div>
						</div>
					);
				})}
			</Wrappertop>
		</Globalwrapper>
	);
};




export default Projects
