import * as React from "react"
import "../assets/css/main.css"
import Layout from "../components/Layout/Layout"
import AboutUs from "../components/Aboutus/AboutUs"
import Services from "../components/Services/Services"
import Team from "../components/Team/Team"
import Projects from "../components/Projects/Projects"
import Gradient from "../components/Gradient/Gradient"
import Blog from "../components/Blog/Blog"
import Background from "../components/Background/Background"
import ScrollToTop from "../components/ScrollToTop/ScrollToTop"
import "animate.css/animate.compat.css";
import Sellingpoint from "../components/Sellingpoint/Sellingpoint"
import Seo from "../components/Seo/Seo"

// import Video from "../components/Video/Video"




const IndexPage = () => {
  return (
    <Layout>
   <Seo title='Dystrybucja kosmetyków, sprzętów i szkolenia kosmetyczne'/>
    <Background/>
    {/* <Video
   
    /> */}
    <AboutUs/>
    <Services/>
    <Projects/>
    <Sellingpoint/>
    <Team/>
    <Blog/>
    <Gradient/>
    <ScrollToTop/>
    </Layout>
  )
}

export default IndexPage
