import React from 'react'
import Contact from '../Contact/Contact'
import Reputation from "../Reputation/Reputation"
import styled from 'styled-components' 

const GradientWrapper = styled.div`
position: relative;
overflow-x: hidden;
&:before {
    content: '';
    position: absolute;
    top: 0;
    left: -50%;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180.53deg, rgb(71,188,202, 0.5) 12.16%, rgba(71,188,202, 0.192708) 12.17%, rgba(71,188,202, 0.5) 12.17%, rgba(255, 255, 255, 0) 50.48%);
    z-index: -1;
    transform: skewX(46deg);
    transition: all .2s ease-in-out;
}
`

const Gradient = () => {
    return (
        <GradientWrapper>

      
        <Reputation/>
        <Contact/>
            
        </GradientWrapper>
    )
}

export default Gradient