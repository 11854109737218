import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import {AiTwotoneMail } from '@react-icons/all-files/ai/AiTwotoneMail';
import {AiOutlineMobile } from '@react-icons/all-files/ai/AiOutlineMobile'
import { device } from '../Device/Device';
import { useCompanyDetails } from "../utils/companyDetails/useCompanyDetails";

const GlobalWraper = styled.section`
	display: flex;
	flex-direction: column;
	/* justify-content: center; */
	h1 {
		text-align: center;
		padding-bottom: 60px;
	}
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	.contact-wrapper {
		display: block;
		width: 100%;
		p {
			display: flex;
			flex-direction: row-reverse;
			justify-content: flex-end;
			font-size: 16px;
			color: rgb(71, 188, 202);

			a {
				display: flex;
				flex-direction: row-reverse;
				align-items: center;
				color: rgb(71, 188, 202);
				svg {
					width: 48px;
					height: 48px;
					margin-right: 20px;
				}
			}
		}
		@media ${device.laptop} {
			display: flex;
			justify-content: center;
			padding-top: 60px;
			p {
				font-size: 24px;
				color: rgb(71, 188, 202);
				padding-right: 40px;
				a {
					display: flex;
					align-items: center;
					color: rgb(71, 188, 202);
					font-size: clamp(0.8rem, -0.0615rem + 1.3462vw, 1.15rem);
					white-space: nowrap;
					svg {
						width: 48px;
						height: 48px;
						margin-left: 20px;
					}
				}
			}
		}
	}
`;

const SmallContact = () => {
	const data = useStaticQuery(graphql`
		{
			markdownRemark(frontmatter: { categories: { eq: "contact" } }) {
				frontmatter {
					title
				}
				html
			}
		}
	`);
	const markdown = data.markdownRemark;
	const page = useCompanyDetails();
	const { company_email, company_phone_second } = page;
	return (
		<GlobalWraper>
			<h1>{markdown.frontmatter.title}</h1>
			<div dangerouslySetInnerHTML={{ __html: markdown.html }} />
			<Wrapper>
				<div className="contact-wrapper">
					<p className="phon-info">
						<a href={`tel:${company_phone_second}`}>
							+{company_phone_second}{" "}
							<span>
								<AiOutlineMobile />
							</span>
						</a>
					</p>
					<p className="email-info">
						<a href={`mailto:${company_email}`}>
							{company_email}
							<span>
								<AiTwotoneMail />
							</span>
						</a>
					</p>
				</div>
			</Wrapper>
		</GlobalWraper>
	);
};

export default SmallContact
