import React, {useRef} from 'react'
import {useStaticQuery, graphql} from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from 'styled-components'
import { device } from '../Device/Device';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {  Pagination, Navigation } from 'swiper';
import 'swiper/swiper-bundle.css'
import { AiFillLeftCircle } from '@react-icons/all-files/ai/AiFillLeftCircle';
import { AiFillRightCircle } from '@react-icons/all-files/ai/AiFillRightCircle';

SwiperCore.use([Pagination,Navigation]);
 

const Team = () => {
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        sort: {fields: frontmatter___order_number}
        filter: {frontmatter: {categories: {eq: "team"}}}) {
        edges {
          node {
            id
            html
            frontmatter {
              title
              image2alt
              Image02 {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, placeholder: TRACED_SVG, width: 335)
                }
              }
            }
          }
        }
      }
    }
  `)
   
    
  
 
  
const edges = data.allMarkdownRemark.edges
const prevRef = useRef(null);
const nextRef = useRef(null);



  return (
<GlobalWrapper id="team" className="globalWrapper">
<div className="navigation-blog">
<Title>Zespół</Title>
<div className="cursor">
              <div ref={prevRef} className="cursor-pointer">
                <span className="prev"><AiFillLeftCircle/></span>
              </div>
              <div ref={nextRef} className="cursor-pointer">
                <span className="next"><AiFillRightCircle/></span>
              </div>
            </div>
            </div>
    <Wrapper>
    <Swiper
            spaceBetween={30}
            slidesPerView={3}
            // loop={true}
            pagination={{ clickable: true }}
            breakpoints={{
              320: {
                slidesPerView: 1,
              },
              535: {
                slidesPerView: 1.5,
              },
              750: {
                slidesPerView: 2,
              },
              991: {
                slidesPerView: 3,
              },
            }}
            navigation={{
              prevEl: prevRef.current ? prevRef.current : undefined,
              nextEl: nextRef.current ? nextRef.current : undefined,
            }}
            onInit={(swiper) => {
              swiper.params.navigation.prevEl = prevRef.current;
              swiper.params.navigation.nextEl = nextRef.current;
            }}
          >
    {edges.map(({node, id}) => {
     const image = getImage(node.frontmatter.Image02)  
              return (
                <SwiperSlide>
                <article key={node.id} className="item">
                <GatsbyImage
                  image={image}
                  alt={node.frontmatter.image2alt}
                  className="gallery-img"
                  imgClassName="image"
                  objectPosition='50% 30%'
                />
                <Wrapperdesc>
                <h3>{node.frontmatter.title}</h3>
                <div dangerouslySetInnerHTML={{ __html:` ${node.html}`}}/>
                
                </Wrapperdesc>
              </article>
              
              </SwiperSlide>
              )
        })}
            
             
      </Swiper>
    </Wrapper>
    </GlobalWrapper>
  )
}
const GlobalWrapper = styled.section `
 padding-top: 60px;
 padding-bottom: 60px;
  position: relative;
  top: -187px;
  background-image:linear-gradient(180deg, rgba(180, 176, 176, 0.3) 4.55%, rgba(180, 176, 176, 0) 99.63%);
  overflow-x: hidden;
  
  z-index: 1;
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border-top: 750px solid #fff;
    border-left: 100vw solid transparent;
    z-index: -1;
  }
  .navigation-blog {
  display: flex;
  justify-content: space-between;
}
.cursor {
  display: flex;
  justify-content: center;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-pointer:nth-of-type(1) {
  margin-right: 60px;
} 
.navigation-blog svg {
  color: #47bcca;
  height: 30px;
  width: 30px;
  transition: 300ms;
  &:hover {
   transform: scale(2) ;
  }
}


`
const Title = styled.h1`
position: relative;
display: block;
    width: fit-content;
    font-weight: normal;
      @media ${device.tablet} {
        margin-bottom:72px ;
      }
`


const Wrapper = styled.div`
  display: flex;
  justify-content: center;
 flex-direction: column;
  align-items: center;
  overflow-x: hidden;
@media ${device.tablet} {
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
}
 & .swiper-container {
   width: 100%;
 }
 
  .item {
      width: 100%;
      height: 450px;
      display: flex;
      flex-direction: column;
      border-radius: 20px;
      margin-bottom: 30px;
      background-color: #fff;
      max-width: calc(335px - 30px);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      @media ${device.tablet} {
        height: 100%;
      }
      @media ${device.laptop} {
        max-width: 335px;
        
      }
}
  /* .item:first-child {
    padding-top: 60px;
@media ${device.tablet}{
  padding-top: 0;
}
 
  } */
  .gallery-img {
      
      height: 100% ;
      max-width: 200px;
      max-height: 200px;
      margin: auto;
      overflow: hidden;
      margin-top: 16px;
      & .image {
        border-radius: 180px;
      }

      @media ${device.tablet} {
      height: 100%;
      border-radius: 20px 20px 0px 0px;
      overflow: hidden;
      max-width: 335px;
      max-height: 380px;
      margin-top: 0px;
      & .image {
        border-radius: 0;
        
      }
      }
    
  }
 
  
`
const Wrapperdesc = styled.div `
display: flex;
flex-direction: column;
gap: 24px;
border-radius:0px 0px 20px 20px;
background-color: #FFF;
padding: 16px 16px 16px 16px ;
width: 100%;
max-width: 100%;
overflow: hidden;

h3 {
    text-align: center;
}

p{
      color: black;
  }
  @media ${device.tablet} {
        height: 220px;
      }
      @media ${device.laptop} {
        height: 220px;
        p{
          font-size: clamp(0.8rem, 0.3077rem + 0.7692vw, 1rem);
      }
      }
`


export default Team
