import React from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

function Counter({ start, end }) {
	return (
		<div>
			<CountUp
				start={start}
				end={end}
				duration={2.75}
				separator=" "
				decimals={0}
				decimal=","
				delay={0}
			>
				{({ countUpRef, start }) => {
					return (
						<VisibilitySensor onChange={start} delayedCall>
							<span ref={countUpRef} />
						</VisibilitySensor>
					);
				}}
			</CountUp>
		</div>
	);
}

export default Counter;
