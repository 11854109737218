import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import {device} from '../Device/Device'
import ScrollAnimation from 'react-animate-on-scroll';



const Globalwrapper = styled.section`
	background-color: #fff;
	position: relative;
	margin-bottom: 100px;
	/* overflow-x:hidden ; */

	&::before {
		content: "";
		display: block;
		position: absolute;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 0;
		border-right: 380px solid rgba(71, 188, 202, 0.19);
		border-left: 0 solid transparent;
		border-top: 200vh solid transparent;
		z-index: 2;
	}

	.about-img {
		height: 100%;
		align-self: flex-end;
		width: 100%;
		margin: 20px auto 0;
		img {
			max-height: 600px;
			width: auto;
			margin: auto;
		}
	}
	.about-img::before {
		z-index: 0 !important;
	}
	.about-img::after {
		z-index: 0 !important;
	}

	h1 {
		position: relative;
		width: max-content;
		font-weight: normal;
	}

	@media ${device.tablet} {
		& .about-img {
			width: 60%;
		}
	}

	@media ${device.laptop} {
		margin-top: 60px;
		&::before {
			border-right: 978px solid rgba(71, 188, 202, 0.19);
		}
		.about-img {
			flex-basis: 60%;
			height: 100%;
			img {
				max-height: 850px;
				width: auto;
			}
		}
	}
	@media ${device.laptop} {
		.about-img {
			flex-basis: 30%;
		}
	}
`;
const Wrapper = styled.div`
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	@media ${device.laptop} {
		flex-direction: row;
	}
`;
const Wrapperdesc = styled.div`
	padding: 30px 20px 0 20px;
	transition: all 0.4s;
	flex-basis: 50%;

	p {
		padding-bottom: 30px;
		transition: all 0.4s;
		&:last-of-type {
			margin-bottom: 0;
			padding-bottom: 0;
		}
	}
	@media ${device.laptop} {
		width: 40%;
		padding-left: 5%;
		p {
			font-size: clamp(1rem, 0.3846rem + 0.9615vw, 1.25rem);
			line-height: 30px;
		}

		p:last-child {
			position: relative;
			padding-bottom: 50px;
		}
	}
`;
const AboutUs = () => {
	const data = useStaticQuery(graphql`
		{
			markdownRemark(frontmatter: { categories: { eq: "aboutus" } }) {
				frontmatter {
					title
				}
				html
			}
		}
	`);
	const markdown = data.markdownRemark;
	// const BackgroundImage = data.childImageSharp

	// const image = getImage(BackgroundImage)

	// Use like this:
	// const bgImage = convertToBgImage(image)

	return (
		<Globalwrapper>
			<Wrapper>
				<Wrapperdesc>
					<h1 id="about">{markdown.frontmatter.title}</h1>
					<ScrollAnimation animateIn="fadeIn">
						<div dangerouslySetInnerHTML={{ __html: markdown.html }} />
					</ScrollAnimation>
				</Wrapperdesc>
				<StaticImage
					src="../../assets/images/aboutus/team_new.png"
					alt="zespół"
					placeholder="blurred"
					layout="CONSTRAINED"
					className="about-img"
					as="div"
				/>
				{/* <BackgroundImage
     
     // Spread bgImage into BackgroundImage:
     Tag="div"
     className="about-img"
     {...bgImage}
     preserveStackingContext
   >
       
   </BackgroundImage> */}
			</Wrapper>
		</Globalwrapper>
	);
};

export default AboutUs
