import React, { useRef } from "react";
import { graphql, useStaticQuery, Link } from "gatsby";

import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ScrollAnimation from "react-animate-on-scroll";
import { AiFillRightCircle } from "@react-icons/all-files/ai/AiFillRightCircle";
import { device } from "../Device/Device";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation } from "swiper/core";
import "swiper/swiper-bundle.css";
import { AiFillLeftCircle } from "@react-icons/all-files/ai/AiFillLeftCircle";

SwiperCore.use([Pagination, Navigation]);

const GlobalWrapper = styled.section`
  position: relative;
  top: -134px;
  overflow-x: hidden;
  padding-top: 20px;
  .navigation-blog {
    display: flex;
    justify-content: space-between;
  }
  .cursor {
    display: flex;
    justify-content: center;
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .cursor-pointer:nth-of-type(1) {
    margin-right: 60px;
  }
  .navigation-blog svg {
    color: #47bcca;
    height: 30px;
    width: 30px;
    transition: 300ms;
    &:hover {
      transform: scale(2);
    }
  }
  .swiper-container {
    width: 100%;
  }
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  .navigation-blog {
    display: flex;
  }
  .item {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    max-width: 500px;
  }
  .about-img {
    margin-bottom: 30px;
    border-radius: 20px;
  }
  a {
    font-family: inherit;
    text-transform: capitalize;
    text-decoration: none;
    color: inherit;
    font-size: 16px;
  }
  .navigation {
    display: flex;
    align-items: center;
  }
  .navigation svg {
    color: #47bcca;
    height: 30px;
    width: 30px;
    margin-left: 30px;
  }
  @media ${device.laptop} {
    .item {
      justify-content: space-between;
      align-items: center;
      margin-bottom: 90px;
    }

    .blog-description {
      flex-basis: 40%;
      & h2 {
        text-transform: lowercase;
        &::first-letter {
          text-transform: uppercase;
        }
      }

      & p:last-of-type {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      @media ${device.laptop} {
        & h2 {
          min-height: 80px;
          height: 100%;
        }
      }
    }
    .about-img {
      width: 100%;
    }
  }
`;
const StyledSpan = styled.span`
  display: flex;
  align-items: center;
  & svg {
    color: #47bcca;
    height: 30px;
    width: 30px;
    margin-left: 30px;
  }
`;

const BlogLink = styled(Link)`
  display: flex;
  align-items: center;
`;

const Blog = () => {
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        sort: { fields: frontmatter___date, order: DESC }
        filter: { frontmatter: { categories: { eq: "blog" } } }
        limit: 6
      ) {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              title
              shortdescription
              imageblogalt
              date(formatString: "MMMM DD, YYYY", locale: "pl-PL")
              Imageblog {
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED
                    placeholder: BLURRED
                    width: 500
                  )
                }
              }
            }
          }
        }
      }
    }
  `);
  const node = data.allMarkdownRemark.edges;

  const prevRef = useRef(null);
  const nextRef = useRef(null);
  return (
    <GlobalWrapper id="blog" className="globalWrapper">
      <div className="navigation-blog">
        <h1>Blog</h1>
        <div className="cursor">
          <div ref={prevRef} className="cursor-pointer">
            <span className="prev">
              <AiFillLeftCircle />
            </span>
          </div>
          <div ref={nextRef} className="cursor-pointer">
            <span className="next">
              <AiFillRightCircle />
            </span>
          </div>
        </div>
      </div>
      <Wrapper>
        <Swiper
          spaceBetween={50}
          slidesPerView={3}
          pagination={{ clickable: true }}
          breakpoints={{
            320: {
              slidesPerView: 1,
            },
            991: {
              slidesPerView: 3,
            },
          }}
          grabCursor={true}
          navigation={{
            prevEl: prevRef.current ? prevRef.current : undefined,
            nextEl: nextRef.current ? nextRef.current : undefined,
          }}
          onInit={(swiper) => {
            swiper.params.navigation.prevEl = prevRef.current;
            swiper.params.navigation.nextEl = nextRef.current;
          }}
        >
          {node.map(({ node }) => {
            const image = getImage(node.frontmatter.Imageblog);

            return (
              <SwiperSlide>
                <article key={node} className="item">
                  <GatsbyImage
                    image={image}
                    alt={node.name}
                    className="about-img"
                  />
                  <div className="blog-description">
                    <p>{node.frontmatter.date}</p>

                    <h2>{node.frontmatter.title}</h2>

                    <ScrollAnimation animateIn="fadeIn">
                      <p>{node.frontmatter.shortdescription}</p>
                      <BlogLink to={node.fields.slug}>
                        Czytaj dalej
                        <StyledSpan>
                          <AiFillRightCircle />
                        </StyledSpan>
                      </BlogLink>
                    </ScrollAnimation>
                  </div>
                </article>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Wrapper>
    </GlobalWrapper>
  );
};

export default Blog;
