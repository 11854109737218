import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import { device } from "../Device/Device";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination, Lazy } from "swiper/core";
import "swiper/swiper.min.css";
import "swiper/components/lazy/lazy.min.css";
import EsteVideo from "../../assets/images/bgimage/este1.mp4";

SwiperCore.use([Autoplay, Pagination, Lazy]);

const GlobalWrapper = styled.section`
	overflow-x: hidden;
	position: relative;
`;
const Wrapper = styled.div`
	.video {
		position: relative;
	}
	.video:before {
		content: "";
		display: block;
		position: absolute;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(41, 59, 92, 0.5);
		z-index: 1;
	}
	.video-player {
		height: 75vh;
		object-fit: cover;
		object-position: center;
	}
	& .slogo-wrapper {
		display: block;
		position: absolute;
		bottom: 10%;
		right: unset;
		top: unset;
		left: 2%;
		width: min(80%, 640px);
		color: #fff;
		padding: 10px;
		z-index: 3;

		& > h1 {
			font-size: 20px;
			font-weight: 700;
		}
	}
	& .slide-wrapper {
		display: none;
	}

	@media (max-width: 1024px) and (orientation: landscape) {
		.video-player {
			height: 100vh;
		}
	}
	@media ${device.laptop} {
		.video-player {
			height: 90vh;
		}
		& .slogo-wrapper {
			top: 25%;
			left: 6%;
			right: 0;
			bottom: 0;
			width: min(60%, 640px);
			color: #fff;
			padding: 10px;
			z-index: 3;
			& h1 {
				font-size: clamp(1.5rem, 1vw + 1.5rem, 3rem);
				font-weight: 600;
				text-align: left;
			}
			& .slogo-wrapper p {
				font-size: clamp(1.25rem, 0.6346rem + 0.9615vw, 1.5rem);
				line-height: 36px;
				text-align: left;
			}
		}
		& .slide-wrapper {
			display: block;
			position: absolute;
			bottom: 5%;
			right: 5%;
			width: min(500px, 60%);
			color: #fff;
			z-index: 3;
			& .swiper-container {
				width: 100%;
			}

			& h2 {
				font-size: clamp(1rem, -0.2308rem + 1.9231vw, 1.5rem);
				line-height: 2rem;
			}
		}
	}
`;

const Background = () => {
	const data = useStaticQuery(graphql`
		{
			markdownRemark(frontmatter: { categories: { eq: "Hero-background" } }) {
				frontmatter {
					title
					slide1
					slide2
					slide3
				}
			}
		}
	`);
	const markdown = data.markdownRemark;

	return (
		<GlobalWrapper>
			<Wrapper>
				<div className="video">
					<video
						className="video-player"
						height="100%"
						width="100%"
						loop
						muted
						autoPlay
						playsInline
					>
						<source src={EsteVideo} typ="video/mp4" />
					</video>
				</div>
				<article className="slogo-wrapper">
					<h1>{markdown.frontmatter.title}</h1>
				</article>
				<div className="slide-wrapper">
					<Swiper
						spaceBetween={30}
						slidesPerView={1}
						loop={true}
						autoplay={{
							delay: 6000,
							disableOnInteraction: false,
						}}
						pagination={{ clickable: true }}

						// breakpoints={{
						//   320: {
						//     slidesPerView: 1,
						//   },
						//   535: {
						//     slidesPerView: 1.5,
						//   },
						//   750: {
						//     slidesPerView: 2,
						//   },
						//   991: {
						//     slidesPerView: 3,
						//   },
						// }}
					>
						<SwiperSlide>
							<h2>{markdown.frontmatter.slide1}</h2>
						</SwiperSlide>
						<SwiperSlide>
							{" "}
							<h2>{markdown.frontmatter.slide2}</h2>
						</SwiperSlide>
						<SwiperSlide>
							<h2>{markdown.frontmatter.slide3}</h2>
						</SwiperSlide>
					</Swiper>
				</div>
			</Wrapper>
		</GlobalWrapper>
	);
};

export default Background;
